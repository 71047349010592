<template>
	<div class="row">
		<div class="col-12" v-if="!disabled">
			<label> {{ $t('global.search') }} </label>
			<InputText v-model="lookup" :disabled="disabled" />

			<BaseActionButton
				v-bind:clicked="clicked"
				v-bind:disabled="disabled || clicked"
				v-on:on-click="lookupCVR"
				type="button"
				id="signupButton"
				class="mt-1"
			>
				{{ $t('company.search') }}
			</BaseActionButton>
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.cvr') }}
			</label>
			<InputNumber v-model="value.cvr" :disabled="disabled" :disableThousandSeparator="true" :required="true" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.name') }}
			</label>
			<InputText v-model="value.name" :disabled="disabled" :required="true" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.address') }}
			</label>
			<InputText v-model="value.address.address" :disabled="disabled" :required="false" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.zip') }}
			</label>
			<InputText v-model="value.address.zip" :disabled="disabled" :required="false" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.city') }}
			</label>
			<InputText v-model="value.address.city" :disabled="disabled" :required="false" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.phone') }}
			</label>
			<InputPhone v-model="value.phone" :disabled="disabled" :required="true" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.contactPersonFullName') }}
			</label>
			<InputText v-model="value.contactPersonFullName" :disabled="disabled" :required="true" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.contactPersonEmail') }}
			</label>
			<InputText v-model="value.contactPersonEmail" :disabled="disabled" :required="true" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.startDate') }}
			</label>
			<InputDate v-model="value.startDate" :required="true" :disabled="disabled" />
		</div>

		<div class="col-12" v-if="!isNew">
			<label> Opsigelse af samarbejde </label>
			<InputDate v-model="value.endDate" :required="false" :disabled="disabled" />
		</div>

		<div class="col-12">
			<label>
				{{ $t('companyInfo.hovedforfald') }}
			</label>
			<InputDropdown v-model="value.hovedforfald" :required="true" :options="months" :disabled="disabled" />
		</div>
	</div>
</template>
<script>
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'
import InputPhone from '@/modules/global/atomComponents/InputPhone.vue'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'

export default {
	name: 'CompanyInfo',
	components: {
		InputDate,
		InputText,
		InputNumber,
		InputPhone,
		InputDropdown,
	},
	props: {
		value: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			clicked: false,
			lookup: '',
			months: [
				{ value: 1, text: 'Januar' },
				{ value: 2, text: 'Februar' },
				{ value: 3, text: 'Marts' },
				{ value: 4, text: 'April' },
				{ value: 5, text: 'Maj' },
				{ value: 6, text: 'Juni' },
				{ value: 7, text: 'Juli' },
				{ value: 8, text: 'August' },
				{ value: 9, text: 'September' },
				{ value: 10, text: 'Oktober' },
				{ value: 11, text: 'November' },
				{ value: 12, text: 'December' },
			],
		}
	},
	watch: {
		value() {
			this.$emit('input', this.value)
		},
	},
	methods: {
		async lookupCVR() {
			try {
				this.clicked = true
				var result = await this.$store.dispatch('companyVuex/lookupCVR', this.lookup)
				this.value.address.address = result.address
				this.value.address.city = result.city
				this.value.address.zip = result.zipcode
				this.value.phone = result.phone
				this.value.name = result.name
				this.value.cvr = result.vat
				this.value.contactPersonEmail = result.email
				this.value.contactPersonFullName = ''
				this.clicked = false
				console.log(result)
			} catch (error) {
				console.log(error.message)
				this.toast('Noget gik galt', error.message, false)
				this.clicked = false
			}
		},
	},
	
}
</script>
<style lang="scss"></style>
